.login-footer-part {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: white;
  height: 7%;
  padding-top: 25px;
}

#alert-dialog-title {
  margin-left: 15px;
}
.login-main-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.login-sub-div {
  display: flex;
  flex-direction: column;
  /* background: radial-gradient(50% 50% at 50% 50%, #e90413 0%, #d01205 100%); */
  background: #fff;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.login-sub-body-part {
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  background: transparent;
}
.login-bodypart {
  display: flex;
  justify-content: center;
  /* background-image: url("../images/logo/TextLogo2.png"); */
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex: 1;
  width: 100%;
}
.login-form-body {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 40%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  flex-direction: column;
  background-color: #09aba9;
}
.login-header-logo {
  display: flex;
  height: 20%;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  /* margin-right: 18%; */
  text-transform: uppercase;
  vertical-align: middle;
  letter-spacing: 1px;
  line-height: 13px;
  font-size: 14px;
  box-sizing: border-box;
  font-family: myriad-pro, Geneva, Tahoma, sans-serif;
  font-style: normal;
}

.login-animatedText-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
  height: auto;
  color: white;
  background: transparent;
  flex-direction: column;
  margin-top: 12%;
  padding: 0 8%;
}
#login-name {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.emailId {
  width: 90%;
  height: 60px;
  padding: 0px 10px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  background: transparent;
  color: white;
  font-size: 16px;
}
.login-form-bodypart {
  display: flex;
  flex-direction: column;
  width: 100%;
}
::placeholder {
  color: white;
}
.textbox-forget-email::placeholder {
  color: orangered;
}
.input-text {
  padding: 15px 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.forgotPassword-div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 90%;
  font-size: 12px;
}
.input-button {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

#login-submitButton {
  background: #f5f5f5;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 38.5px;
  width: 85%;
  height: 42px;
  border: none;
  color: #09aba9;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}
#span-title {
  font-weight: bold;
  letter-spacing: 2.9px;
  margin-left: 1px;
}
.div-title-name {
  padding: 2px;
  color: white;
  margin-right: 20px;
  line-height: 1.2;
}

#image-logo {
  height: 75px;
}
.login-tile-name {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  padding: 10px;
}
.textbox-forget-email {
  width: 90%;
  height: 40px;
  padding: 0px 10px;
  border: 1px solid orangered;
  border-radius: 3px;
  background: transparent;
  color: orangered;
}
#Send-email-button {
  height: 35px;
  width: 120px;
  font-size: 15px;
  color: white;
  background-color: orangered;
  border: none;
  border-radius: 35px;
  /* margin-right: 21px; */
  outline: none;
  margin: 18px;
}
#save-password-button {
  height: 44px;
  width: 233px;
  font-size: 15px;
  color: white;
  background-color: orangered;
  border: none;
  border-radius: 35px;
  /* margin-right: 21px; */
  outline: none;
  margin: 18px;
}
.dialog-input-error-message {
  height: 15px;
  padding: 8px 17px;
  align-items: center;
  display: flex;
}
#dialog-span-error-message {
  color: orangered;
  font-size: 12px;
  height: 14px;
}
.dialog-close-button {
  background: orangered;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  font-family: arial;
  font-size: 25px;
  cursor: pointer;
  z-index: 99992;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  margin: 3px;
}
.login-error-message {
  color: red;
  margin-left: 8%;
  height: 16px;
  font-size: 14px;
  padding: 5px 0;
}

.tagLine1 {
  font-size: 2.5em;
  animation-duration: infinite;
  -webkit-animation: fadeout 5s;
  -moz-animation: fadeout 5s; /* Firefox < 16 */
  -ms-animation: fadeout 5s; /* Internet Explorer */
  -o-animation: fadeout 5s; /* Opera < 12.1 */
  animation: fadeout 5s;
  color: white;
  animation-iteration-count: infinite;
  font-weight: 500;
  font-style: italic;
  font-family: "Galada", cursive;
  letter-spacing: 2px;
}
.tagLine2 {
  font-size: 2.5em;
  animation-duration: infinite;
  -webkit-animation: fadeTagline2 5s;
  -moz-animation: fadeTagline2 5s; /* Firefox < 16 */
  -ms-animation: fadeTagline2 5s; /* Internet Explorer */
  -o-animation: fadeTagline2 5s; /* Opera < 12.1 */
  animation: fadeTagline2 5s;
  color: white;
  animation-iteration-count: infinite;
  margin-left: 32%;
  padding: 20px;
  font-weight: 500;
  font-style: italic;
  font-family: "Galada", cursive;
  letter-spacing: 2px;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeTagline2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 799px) {
  .login-sub-body-part {
    display: flex;
    background: transparent;
    flex-direction: column;
    flex: 1;
    padding: 0 5%;
  }
  .login-form-body {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    width: 100%;
  }
  .login-animatedText-body {
    display: flex;
    color: white;
    background: transparent;
    flex-direction: column;
    width: 100%;
  }
  .login-header-logo {
    display: flex;
    height: 20%;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 1px;
    line-height: 13px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: myriad-pro, Geneva, Tahoma, sans-serif;
    font-style: normal;
  }
  #image-logo {
    height: 50px;
  }
}
