.DashboardEmployee-main-div {
  display: flex;
  justify-content: baseline;
  margin: 20px 0;
  width: 100%;
  flex-direction: column;
}
.dashboardCard {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-around;
}
.dashboardCard-contain-one {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboardCard-contain-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.activityStatus-Title {
  color: gray;
  font-size: 10px;
  line-height: 12px;
}
.activityStatus-Number {
  font-size: 32px;
}

#scoreLevelContainer::-webkit-scrollbar {
  width: 1px;
  height: 3px;
  border: none;
}
#scoreLevelContainer::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
  border: none;
}
#scoreLevelContainer::-webkit-scrollbar-thumb {
  background: #80808075;
  /* border: 0.1px solid whitesmoke; */
  border-radius: 10px;
  border: none;
}
#scoreLevelContainer::-webkit-scrollbar-thumb:hover {
  background: #80808075;
}
.descriptionMyrewards {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}
.MuiList-root::-webkit-scrollbar {
  width: 4px;
  height: 1px;
  border: none;
}
.MuiList-root::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
  border: none;
}
.MuiList-root::-webkit-scrollbar-thumb {
  background: #80808075;
  /* border: 0.1px solid whitesmoke; */
  border-radius: 10px;
  border: none;
}
.MuiList-root::-webkit-scrollbar-thumb:hover {
  background: #80808075;
}
.dashboardImageDivider {
  width: 1px;
  height: 65%;
  background-color: #000000;
  opacity: 0.2;
  display: flex;
}
