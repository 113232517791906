.main-header-div {
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex: 1;
  margin-bottom: 5em;
}

.imageTitle {
  display: flex;
  flex-direction: row;
  white-space: normal;
}
.imageLogo {
  text-decoration: none;
}
.imageLogo-overview-div {
  text-decoration: none;
  padding: 0 15px;
}
.imageLogo-notification {
  text-decoration: none;
  margin-left: auto;
  /* padding: 0 15px; */
}
#txtsearch {
  display: flex;
  font-size: 12px;
  background: transparent;
  border: none;
  width: 100%;
  min-height: 45px;
  outline: none;
  padding: 0 20px;
}
#txtsearch::placeholder {
  color: rgba(138, 132, 132, 0.8);
}
.HeaderLogos {
  cursor: pointer;
  height: 25px;
}
#searchButton {
  background: transparent;
  border: none;
  color: #e14301;
  cursor: pointer;
}

@media (max-width: 799px) {
  .notification-profile-div {
    display: none;
  }
  .dropdown {
    margin-left: auto;
    position: relative;
    display: inline-block;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: rgb(223, 0, 15);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: initial;
  }
  .dropbtn {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .dropdown-content span {
    color: darkseagreen;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-transform: capitalize;
  }

  .dropdown-content span:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .dropdown-content a:hover {
    background-color: blue;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover .dropbtn {
    background-color: transparent;
  }
  #headerLogo {
    cursor: pointer;
    width: 110px;
  }
  #headerCompnayLogo {
    cursor: pointer;
    width: 140px;
  }
  .Header-search-container {
    display: flex;
    align-items: center;
    background: rgb(243, 243, 236);
    outline: none;
    background-color: #ffffff;
    color: rgba(138, 132, 132, 0.8);
  }
  .imageLogo {
    text-decoration: none;
  }
}

@media (min-width: 800px) {
  .notification-profile-div {
    display: flex;
    flex-direction: row;
    width: 5%;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
  .dropdown {
    display: none;
  }
  #menu-icon {
    font-size: 40px;
  }
  #headerLogo {
    cursor: pointer;
    height: 45px;
  }
  #headerCompnayLogo {
    cursor: pointer;
    height: 60px;
  }
  .Header-search-container {
    margin: 0 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(243, 243, 236);
    padding: 0 10px;
    outline: none;
    background-color: #fcf8f8;
    color: rgba(138, 132, 132, 0.8);
    width: 400px;
    height: 36px;
  }
}
.popover:hover {
  background: #fcf8f8;
  color: red;
  cursor: pointer;
}
.dropdown-div {
  padding: 1px 5px;
}
