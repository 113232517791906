.home-main-div {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
}
.create-activity-div {
  display: flex;
  justify-content: center;
  height: 20%;
}
#addActivityButton-image {
  height: 80px;
  cursor: pointer;
  width: 20rem;
}
.select-year {
  height: 47px;
  background: #ffffff;
  width: 97px;
  border: none;

  border: 1px solid #80808047;
}
#ReportDownloadButton {
  padding: 0px 15px;
  height: 48px;
  padding-top: 2px;
  cursor: pointer;
}
#RefreshButton {
  height: 48px;
  cursor: pointer;
}
/* .hover-div:hover {
  background: #ffffff;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  z-index: 99999;
} */
.hover-div.MuiTableRow-root.MuiTableRow-hover:hover {
  background: #ffffff;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

#dialogTitle-Activity {
  color: orangered;
  font-size: 21px;
  padding: 15px;
}

.containt-title-dialog {
  align-content: baseline;
  display: flex;
  padding: 5px 21px;
  font-size: 13px;
}
.textbox-Heading-topic {
  width: 90%;
  padding: 0 10px;
  height: 38px;
  background: #fcfcfc;
  border: 0.7px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.45);
  padding: 10px;

  outline: none;
}
.textbox-Heading-topic::placeholder {
  color: rgba(138, 132, 132, 0.8);
}
.textarea-Heading-topic {
  width: 90%;
  padding: 0 10px;
  background: #fcfcfc;
  border: 0.7px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
  min-height: 20px;
}
.textarea-Heading-topic::placeholder {
  color: rgba(138, 132, 132, 0.8);
}

#dueDate::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
#status-button {
  background-color: transparent;
  color: white;
  text-transform: capitalize;
  font-size: 0.875rem;
}
.popover.MuiTypography-body1 {
  font-size: 0.9rem;
  letter-spacing: 0.00938em;
}
#cancel-button-status {
  color: rgba(226, 0, 15, 0.5);
  background-color: transparent;
  margin-left: auto;
}
#Filter-button-status {
  color: #e2000f;
  background-color: transparent;
}
.button-status-div {
  display: flex;
}
.from-date-to-div {
  display: flex;
  flex-direction: row;
}
#from-date {
  width: 130px;
}
#to-date {
  width: 130px;
}
.from-date-div {
  padding: 0 10px;
}
.Tooltip-span {
  font-size: 1.5em;
}
.date-report-refresh-div {
  display: flex;
  flex: 1;
}
@media (max-width: 799px) {
  .search-report-refresh-div {
    display: flex;
    padding: 40px 0;
    align-items: center;
    flex-direction: column;
  }
  .date-report-refresh-div {
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
  .search-container-activity {
    display: flex;
    align-items: center;
    outline: none;
    background-color: #ffffff;
    color: rgba(138, 132, 132, 0.8);
    background: #ffffff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 310px;
    height: 45px;
  }
}

@media (min-width: 800px) {
  .search-report-refresh-div {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    height: 90px;
    padding: 0px 15px;
  }
  .search-container-activity {
    display: flex;
    outline: none;
    /* background-color: #ffffff; */
    /* color: rgba(138, 132, 132, 0.8); */
    /* background: #ffffff; */
    /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25); */
    /* border-radius: 5px; */
    width: 350px;
    border: 1px solid #80808047;
    height: 45px;
    margin-right: 15px;
  }
  .select-date-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#titleOfThePage {
  font-size: 14px;
  font-weight: 350;
  letter-spacing: 0.2px;
}
.filterTags {
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  background-color: white;
  min-height: 50px;
  max-height: 100px;
  padding: 0 15px;
}
.select-role {
  margin-top: 7px;
  height: 40px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  min-width: 265px;
  background: rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
  box-sizing: border-box;
}
.select-role-unit {
  height: 40px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  width: 100px;
  background: rgba(0, 0, 0, 0.025);
  border-radius: 4px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
  box-sizing: border-box;
  margin-left: 17px;
  margin-top: 2px;
}
