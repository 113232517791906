.Expanded-main-div {
  overflow-y: auto;
  align-items: center;
  justify-content: baseline;
  display: flex;
  flex-direction: column;
  flex: 1;
  position:fixed;
  width: 315px;
}
.profile-photo-name {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #81cc87;
  height: auto;
  padding: 10px 0px;
}
#user-profile-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
  color: black;
}
#user-profiled-department {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: black;
}
#profile-photo {
  width: 66px;
  height: 66px;
}
.points-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #b3000f;
  width: 100%;
  padding: 10px 0px;
}
#level-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffe248;
  padding: 10px;
}
.points-rank {
  display: flex;
  justify-content: center;
  align-items: center;
}
.points {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
}
#title-points {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
#value-point {
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  padding: 8px 0;
}
.verticalLine {
  height: 27px;
  margin-top: 16px;
  border: 0.7px solid #ffffff;
}
.next-level {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}
#next-level-under-div {
  height: 67px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f1f1f1;
  font-size: 16px;
}
.Leaderboard {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#leaderboard-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #e2000f;
}
.bar-chart {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.bar2 {
  width: 60px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    #fc0202 0%,
    #ee3939 0.01%,
    rgba(247, 59, 59, 0.36) 100%
  );
}
.bar1 {
  width: 60px;
  height: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    #fc0202 0%,
    #ee3939 0.01%,
    rgba(247, 59, 59, 0.36) 100%
  );
  margin: 0px 15px;
}
.bar3 {
  width: 60px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    #fc0202 0%,
    #ee3939 0.01%,
    rgba(247, 59, 59, 0.36) 100%
  );
}
#image-rank {
  width: 61px;
  height: 61px;
}
.mine-level {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 11px rgba(135, 122, 122, 0.3);
  border-radius: 0px;
  width: 255px;
  padding: 10px 0;
}
.mine-level-under-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#image-rank-level {
  width: 40px;
  height: 40px;
}
.centered {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
}
.image-rank-number {
  position: relative;
  text-align: center;
  color: #eec10f;
}
#image-round {
  width: 30px;
  height: 30px;
}
#points-number {
  color: #e2000f;
}
#points {
  color: #827a7b;
}

.horizontalLine {
  width: 225px;
  margin: 0px 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}
.redeemPoints-btn {
  width: 210px;
  height: 48px;
  background: #e2000f;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  outline: none;
  border: none;
  cursor: pointer;
}
.redeemPoints-div {
  display: flex;
  padding: 100px 0;
}
.imageBars {
  position: relative;
  text-align: center;
  color: white;
}
#imagebarsId {
  width: 210px;
}
.leftBar2 {
  position: absolute;
  bottom: 27px;
  left: 20px;
  width: 0;
}
.centerBar1 {
  position: absolute;
  top: 33%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 0px;
}
.rightBar1 {
  position: absolute;
  bottom: 23px;
  right: 61px;
  width: 0px;
}
.pointsLeaderBoard {
  font-size: 18px;
}
.pointsKeyword {
  font-size: 14px;
}
.barLevel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.barLevel-main {
  display: flex;
  position: relative;
  flex-direction: column;
}

@media (max-width: 1208px) {
  .Expanded-main-div {
    /* width: 26%; */
  }
}

@media (max-width: 1000px) {
  .Expanded-main-div {
    /* width: 23%; */
  }
}
@media (min-width:1400px) and (max-width:2000px){
  .Expanded-main-div {
    overflow-y: auto;
    align-items: center;
    justify-content: baseline;
    display: flex;
    flex-direction: column;
    flex:1;
    position:fixed;
    /* width: 19%; */
  }
}