.create-project-cntr {
  padding: 1em;
  color: #495156;
}

.header-cntr.header-cntr {
  display: flex;
  justify-content: space-between;
}

/* .form-cntr {
}

.feilds-cntr {
} */

.feild {
  margin-top: 2em;
  display: flex;
  gap: 2rem;
}

/* .feilds-cntr{
  margin: 2em;
} */

.label-text {
  min-width: 10%;
  max-width: 10%;
}

.select-state{
  margin-top: 1em;
  width: 20%;
}

.inp-feild {
  flex: 1;
  height: 2.7rem;
  margin-top: 0.5em;
  
}

.prj-details-cntr {
  border: 1px solid rgb(206, 203, 203);
  flex: 1;
  /* display: flex; */
  padding: 1rem;
  gap: 0.3rem;
}

.details-cntr {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid grey;
  /* height: 3em; */
  gap: 0.3rem;
  margin-bottom: 1em;
  padding:  0.3em;
}

.prj-details-cntr input {
  width: 100%;
}

.descriptiontrext {
  flex: 1;

  height: 2.3rem;
  gap: 0.3rem;
  display: flex;
  align-items: center;
}

.project-details {
  display: flex;
  gap: 0.3rem;
}

.upload-cntr {
  flex: 1;
  width: 100%;
}

.create-btn {
  margin-top: 3em;
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
  width: 21%;
}

.details {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.btn-bg {
  background-color: #546fe6;
  color: white;
  height: 3em;
  margin-top: 0.5em;
}

.deleteBtn {
  color: red;
  outline: 0;
  border: 0;
  background-color: #f4f4f4;
}
.deleteBtn:hover{
    color: red;
}

.unit-cntr{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;  
}

.unit-title {
  flex: 1 ;
  margin-top: 0.5em;
}

.unit-detail{
  flex: 1 ;
  margin-top: 0.5em;
}

.unit-btn{
  width: 30%;
}

.ant-select-selection {
  background-color: green;
}
