.layout1-main-div {
  overflow-y: auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.layout1-header-div {
  display: flex;
  flex-direction: column;
}
.layout1-sidebar-div {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 15px;
  background: #80808017;
}
.layout1-simpleSidebar-div {
  background: #ffffff;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.layout1-expandedSidebar-div {
  background: #ffffff;
  height: 100%;
  min-width: 315px;
  display: flex;
  flex-direction: column;
}
.layout1-nestedRouter-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 824px) {
  .layout1-expandedSidebar-div {
    display: none;
  }
  .layout1-simpleSidebar-div {
    display: none;
  }
}
